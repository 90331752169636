export enum RootRoute {
  Achievement = 'achievement',
  About = 'about',
  Administration = 'administration',
  Blogs = 'blogs',
  Causes = 'causes',
  Community = 'community',
  Competition = 'competition',
  Contact = 'contact',
  Discovery = 'discovery',
  EmailAuthRedirect = 'email-auth-redirect',
  Embed = 'embed',
  Faq = 'faq',
  Forbidden = 'forbidden',
  Chat = 'chat',
  CuratedForms = 'curated-forms',
  FormResponse = 'form-response',
  Helpdesk = 'helpdesk',
  Landing = 'landing',
  Login = 'login',
  Match = 'match',
  MfaLink = 'mfa-link',
  News = 'news',
  NotFound = 'notfound',
  Oauth2 = 'oauth2',
  OauthCallback = 'oauth2/callback',
  PartyFinder = 'party-finder',
  Premium = 'premium',
  Privacy = 'privacy',
  Register = 'register',
  Registration = 'registration',
  ResetPassword = 'resetPassword',
  Resources = 'resources',
  Root = '',
  Settings = 'settings',
  Style = 'style',
  TermsOfUse = 'terms-of-use',
  User = 'user',
  VerifyDomainCode = 'validateEmail',
  VerifyEmailCode = 'verifyEmail',
  Squad = 'squad',
  FrostGiantSteam = 'frost-giant-steam',
  FrostGiantSteamCallback = 'frost-giant-steam/callback',
  FrostGiantSteamError = 'frost-giant-steam/error'
}

export enum AdministrationRootroute {
  Configuration = 'configuration',
  Entities = 'entities',
  Tools = 'tools',
  Styles = 'styles',
  Organizations = 'organizations'
}

export enum AdministrationSubRoute {
  AceOrg = 'ace-org',
  AccessGroups = 'access-groups',
  AllowUser = 'allow-user',
  AuditLog = 'audit-log',
  CompetitionUpdate = 'competition-update',
  CreateCommunity = 'create-community',
  CreateCompetition = 'create-competition',
  CreateLeague = 'create-league',
  CreateOrganization = 'create-organization',
  CuratedForms = 'curated-forms',
  FeaturesCommunication = 'features-communication',
  FeaturesCommunity = 'features-community',
  FeaturesSquads = 'features-squads',
  FeaturesDevOps = 'features-devops',
  FeaturesImages = 'features-images',
  FeaturesRegistration = 'features-sign-up',
  FeaturesStyling = 'features-styling',
  FeaturesWhitelabeling = 'features-whitelabeling',
  ManageAchievements = 'manage-achievements',
  ManageCauses = 'manage-causes',
  ManageChallenges = 'manage-challenges',
  ManageGames = 'manage-games',
  ManageIcons = 'manage-icons',
  ManageIndices = 'manage-indecies',
  ManageOffers = 'manage-offers',
  ManageOrganization = 'manage-organization',
  ManagePermits = 'manage-permits',
  ManagePlatforms = 'manage-platforms',
  ManageProducts = 'manage-products',
  ManageSubmissionScores = 'manage-submission-scores',
  ManageUrls = 'manage-urls',
  MergeCommunities = 'merge-communities',
  OrgStyles = 'org-styles',
  QueryUsers = 'query-users',
  ValidateUser = 'validate-user'
}

export enum AdminStyleRoute {
  Alerts = 'alerts',
  Overview = 'overview',
  Buttons = 'buttons',
  Typography = 'typography',
  Chips = 'chips',
  Icons = 'icons'
}

export enum CommunityRoute {
  Achievements = 'achievements',
  Members = 'members',
  Squads = 'squads',
  Settings = 'settings',
  Plans = 'plans',
  Chat = 'chat'
}

export enum CommunityOverviewRoute {
  Activity = 'activity',
  Tweets = 'tweets'
}

export enum CommunitySettingsRoute {
  General = 'general',
  BannerIcon = 'bannericon',
  Registration = 'reg',
  SocialLinks = 'social-links',
  Achievements = 'achievements',
  Discord = 'discord',
  MemberSettings = 'membersettings',
  ArchiveCommunity = 'archivecommunity',
  ImageConfig = 'image-config',
  DangerZone = 'dangerzone',
  ApprovalQueue = 'approval-queue',
  TicketQueue = 'ticket-queue',
  UserAccess = 'user-access',
  Invites = 'invites'
}

export enum CompetitionRoute {
  Bracket = 'bracket',
  Info = 'info',
  Match = 'match',
  Matches = 'matches',
  Team = 'team',
  Settings = 'settings',
  Chat = 'chat'
}

export enum CompetitionSettingsRoute {
  Audit = 'audit',
  Broadcast = 'broadcast',
  General = 'general',
  ImageConfig = 'image-config',
  Requirements = 'requirements',
  Permits = 'permits',
  Communications = 'communications',
  Donations = 'donations',
  SocialLinks = 'social-links',
  Curatedforms = 'curated-forms',
  UserAccess = 'user-access',
  TicketQueue = 'ticket-queue',
  ManageTeams = 'manage-teams',
  ManageMatches = 'manage-matches'
}

export enum CompetitionInfoRoute {
  Broadcast = 'broadcast',
  Details = 'details',
  Activity = 'activity',
  Rules = 'rules',
  Chat = 'chat'
}

export enum CompetitionTeamsRoute {
  Team = 'team',
  Admin = 'admin',
  All = 'all',
  FreeAgent = 'free-agent',
  Mine = 'mine'
}

export enum CompetitionMatchesRoute {
  All = 'all',
  Mine = 'mine',
  MatchLinks = 'match-links'
}

export enum DiscoveryRoute {
  Communities = 'communities',
  Competitions = 'competitions',
  Squads = 'squads',
  PartyFinder = 'party-finder'
}

export enum DiscoverySubRoute {
  My = 'my'
}

export enum PartyFinderRoute {
  HostParty = 'host-party',
  PostInterest = 'post-interest',
  Interest = 'interest',
  Party = 'party',
  PartyFinderEvent = 'event',
  Parties = 'parties',

  // vnext
  FindMore = 'find-more',
  Applications = 'applications',
  Members = 'participants',
  Invites = 'invites',
  Membership = 'membership'
}

export enum PartySlug {
  Apply = 'apply',
  Participantion = 'participation',
  Chat = 'chat',
  Discord = 'discord',
  Invite = 'invite',
  Polls = 'polls',
  Settings = 'settings'
}

export enum InterestSlug {
  Edit = 'edit'
}

export enum SettingsRoute {
  Achievements = 'user-achievements',
  AccountSettings = 'account-preferences',
  AccountInfo = 'account-info',
  DependentAccounts = 'dependent-accounts',
  Notifications = 'notifications',
  ProfileCard = 'profile-card',
  FormResponses = 'form-responses'
}
